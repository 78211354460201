import React, { useEffect } from 'react';
import { Switch, Redirect, useParams } from 'react-router-dom';
import {
  LoginCallback,
  PostAuthentication,
  SingleSignon,
} from '@tekion/tap-components/middlewares/authentication';
import { Security } from '@okta/okta-react';
import { Route } from '@tekion/tap-components/middlewares/applicationMonitoring/ElasticAPM';
import ErrorPage from '@tekion/tap-components/atoms/ErrorPage';
import TokenManager from '@tekion/tap-components/utils/TokenManager';

import SignIn from '../../signIn';
import StaticSignUp from '../../SignUp';
import Settings from '../../Settings';
// import Profile from '../../Settings/Profile';
import TaskManagement from '../../Settings/TaskManagement';
import Calendar from '../../Settings/Calendar';
import SupportPortal from '../../Settings/SupportPortal';
import NotificationSettings from '../../Settings/NotificationSettings/NotificationSettings';
import TapApplicationsContainer from '../../TapApplictions/TapApplicationsContainer';
import TapApplications from '../../TapApplictions/TapApplications';
import Listing from '../../TapApplictions/Listing';
import CreateModule from '../../TapApplictions/CreateModule';
import ReleaseVersion from '../../TapApplictions/ReleaseVersion';
import Home from '../../Home/home';
import CardManagement from '../../TapApplictions/CardManagement/CardManagement';
import CreateForm from '../../TapApplictions/CardManagement/CreateForm';
import GroupManagement from '../../TapApplictions/GroupManagement/GroupManagement';
import GroupCreateForm from '../../TapApplictions/GroupManagement/GroupCreateForm';
import TapConfigurations from '../../TapApplictions/TapConfigurations';
import MobileConfigurations from '../../TapApplictions/MobileConfigurations/index';
import MobileConfigurationsCreate from '../../TapApplictions/MobileConfigurations/MobileConfigurationsCreate/index';
import TapConfigCreateForm from '../../TapApplictions/TapConfigurations/TapConfigCreateForm';
import Desktop from '../../Desktop';
import UserManagement from '../../UserManagement';
import UserImageMigration from '../../UserImageMigration/UserImageMigration.container';
import CalendarAvailability from '../../Settings/Availability';
import FeatureTracker from '../../Settings/FeatureTracker';
import DuoAuthCallback from '../../DuoAuthCallback';

const SQLifyRedirect = () => {
  const { memoryKey } = useParams();
  useEffect(() => {
    window.location.href = `/platform/sqlify${memoryKey ? `/${memoryKey}` : ''}`;
  }, [memoryKey]);

  return null;
};

const RouteWithPermission = props => (
  <Security
    oktaAuth={TokenManager.getAuthClient()}
    restoreOriginalUri={() => { }}
  >
    <Switch>
      <Route exact path="/login" component={(props) => <SignIn {...props} isDuoAuth />} />
      <Route exact path="/app/duo/callback" component={DuoAuthCallback} />
      <Route exact path="/login/okta" component={SignIn} />
      <Route exact path="/signup" component={StaticSignUp} />
      <Redirect exact from="/app/settings/profile" to="/app/settings/calendar" />
      <Redirect exact from="/app/settings" to="/app/settings/calendar" />
      <Route
        exact
        path="/app/user-management"
        component={() => <UserManagement apiBaseUrl={process.env.BASE_URL} />}
      />
      <Route
        exact
        path="/app/user-migration"
        component={() => <UserImageMigration apiBaseUrl={process.env.BASE_URL} />}
      />
      <Route
        path="/app/settings"
        component={() => (
          <Settings>
            <Route
              path="/app/settings/availability"
              exact
              component={() => <CalendarAvailability apiBaseUrl={process.env.BASE_URL} {...props} />}
            />
            <Route
              path="/app/settings/taskmanagement"
              exact
              component={() => (
                <TaskManagement apiBaseUrl={process.env.BASE_URL} />
              )}
            />
            <Route
              path="/app/settings/calendar"
              exact
              component={() => <Calendar apiBaseUrl={process.env.BASE_URL} {...props} />}
            />
            <Route
              path="/app/settings/supportportal"
              exact
              component={() => <SupportPortal apiBaseUrl={process.env.BASE_URL} />}
            />
            <Route
              path="/app/settings/notification"
              exact
              component={() => <NotificationSettings apiBaseUrl={process.env.BASE_URL} />}
            />
            <Route
              path="/app/settings/feature-tracker"
              exact
              component={() => <FeatureTracker apiBaseUrl={process.env.BASE_URL_2} />}
            />
          </Settings>
        )}
      />
      <Route exact path="/app/callback" component={LoginCallback} />
      <Route
        exact
        path="/app/singlesignon"
        component={() => (
          <SingleSignon
            BASE_URL={process.env.BASE_URL}
            AUTHORIZATION_SERVER_ID={process.env.AUTHORIZATION_SERVER_ID}
            authenticateApiUrl="/tapapi/v2/usermanagement/authenticate-okta-user"
            clientId={process.env.OKTA_CLIENT_ID}
            redirectUri={`${window.location.origin}/app/singlesignon`}
            scope="openid profile email"
          />
        )}
      />
      <Route
        path="/app/tapapplications"
        component={() => (
          <TapApplications>
            <Switch>
              <Route
                exact
                path="/app/tapapplications"
                component={() => <TapApplicationsContainer apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/releaseversion"
                component={() => <ReleaseVersion apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/cards"
                component={() => <CardManagement apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/cards/create"
                component={() => <CreateForm apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/cards/:appKey/edit"
                component={() => <CreateForm apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/groups"
                component={() => <GroupManagement apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/groups/create"
                component={() => <GroupCreateForm apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/groups/:appKey/edit"
                component={() => <GroupCreateForm apiBaseUrl={process.env.BASE_URL} isEdit />}
              />
              <Route
                exact
                path="/app/tapapplications/tapconfig"
                component={() => <TapConfigurations apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/tapconfig/create"
                component={() => <TapConfigCreateForm apiBaseUrl={process.env.BASE_URL} isCreate />}
              />
              <Route
                exact
                path="/app/tapapplications/tapconfig/:module/edit"
                component={() => <TapConfigCreateForm apiBaseUrl={process.env.BASE_URL} isEdit />}
              />
              <Route
                exact
                path="/app/tapapplications/mobileconfigurations"
                component={() => <MobileConfigurations apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/mobileconfigurations/create"
                component={() => <MobileConfigurationsCreate apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/mobileconfigurations/edit/:platform"
                component={() => <MobileConfigurationsCreate apiBaseUrl={process.env.BASE_URL} isEdit />}
              />
              <Route
                exact
                path="/app/tapapplications/:feature"
                component={() => <Listing apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/:feature/create"
                component={() => <CreateModule apiBaseUrl={process.env.BASE_URL} />}
              />
              <Route
                exact
                path="/app/tapapplications/:feature/:module/edit"
                component={() => <CreateModule apiBaseUrl={process.env.BASE_URL} />}
              />
            </Switch>
          </TapApplications>
        )}
      />
      <Route
        exact
        path="/app/home"
        component={() => <Home />}
      />
      <Route
        exact
        path="/app/setup"
        component={() => (
          <PostAuthentication
            BASE_URL={process.env.BASE_URL}
            authenticateApiUrl="/tapapi/v2/usermanagement/authenticate-okta-user"
          />
        )}
      />
      <Route
        exact
        path="/app/sqlify/:memoryKey?"
        component={() => <SQLifyRedirect />}
      />
      <Route
        exact
        path="/app/desktop/:app"
        component={routerProps => (
          <Desktop {...routerProps} />
        )}
      />
      <Redirect exact from="/" to="/login" />
      <Route
        path=""
        component={routerProps => <div style={{ padding: '4rem' }}> <ErrorPage {...routerProps} notFound /></div>}
      />
    </Switch>
  </Security>
);

export default RouteWithPermission;
